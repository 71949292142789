.questionSection {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-image: url("/public/background-map-blue-mobile.webp");
    background-repeat: repeat;
}

.questionWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 75%;
    margin-top: 33px;
}

.topWrapper {
    display: flex;
    justify-content: space-between;
    width: 99%;
}

.logo {
    width: 100px;
}

.hintButtonWrapper {
    user-select: none;
    /* Standard syntax */
    -webkit-user-select: none;
    /* Safari/Chrome, other WebKit */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    box-sizing: border-box;
    position: relative;
    right: -17%;
    border: solid var(--Yellow, #FFD000);
    border-right: 0px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.buttonText {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
}

.hintButton {
    box-sizing: border-box;
    width: 100%;
    color: var(--Black, #121212);
    outline: 3px solid var(--Yellow, #FFD000);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0px;
    background: var(--Yellow, #FFD000);
    padding: 9px 23px 9px 14px;
}

.disabledHintText {
    color: var(--gray-dark, #707070);
}

.withoutHintsText {
    color: var(--Gray-dark, #707070);
    font-family: Montserrat;
    font-size: 14px;
    line-height: 80%;
    margin: 0px;
}

.disable {
    padding: 8px 23px 8px 14px;
}

.mapButton {
    color: var(--White, #FFF);
    margin-top: 5px;
    margin-left: 17px;
    margin-bottom: 12px;
    padding-top: 5px;
}

.questionImage {
    width: 100%;
    margin-bottom: 10px;
    outline: 2px solid var(--yellow);
    border-radius: 5px;
}

.yellow {
    color: var(--yellow)
}

.title {
    display: inline-block;
    color: var(--Yellow, #FFD000);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    margin-top: 43px;
    margin-bottom: 0px;
}

.titleUnderline {
    width: 110%;
    height: 2px;
    background-color: var(--yellow);
    margin: 6px 0 0 -5%;
}

.infoWrapper {
    display: flex;
    justify-content: space-between;
    width: 110%;
    margin-left: -5%;
}

.infoText {
    color: var(--Gray-mid, #D0D0D0);
    font-family: Montserrat;
    font-size: 16px;
    margin-top: 6px;
}

.textWrapper, .adviceWrapper {
    color: var(--White, #FFF);
    font-family: Montserrat;
    font-size: 14px;
    margin-top: 49px;
}

.adviceWrapper {
    margin-top: 15px;
    margin-bottom: 150px;
    height: 20px;
    width: 100%;
    overflow: hidden;
}

.show {
    font-style: italic;
    margin-bottom: 10px;
}

.expanded {
    height: auto;
    padding: 5px
}

.expanded>.show {
    color: var(--yellow)
}

.hintTitle {
    font-style: italic;
    color: var(--success)
}

.hint {
    margin: 10px 0;
}

.inputWrapper {
    width: 100%;
    margin-top: 26px;
    /* margin-bottom: 150px; */
}

.questionInput {
    width: 90%;
    height: 31px;
    font-family: Montserrat;
    font-size: 14px;
    color: white;
    border: 1px solid var(--Gray-dark, #707070);
    background-color: transparent;
    border-radius: 5px;
    padding-left: 20px;
}

.questionInput:focus {
    border-color: white;
    outline: none;
}

.question {
    margin: 10px 0;
    font-weight: 800;
}

.incorrectAnswerText, .incorrectAnswer {
    color: var(--Error, #C14C50);
    font-family: Montserrat;
    font-size: 14px;
}

.incorrectAnswerText {
    margin-left: 20px;
    margin-top: 7px;
}

.buttonWrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    bottom: 80px;
}