.desktop {
    display: block
}

.mobile {
    display: none
}

.wrapper {
    z-index: 55;
    position: fixed;
    width: 100%;
    height: 70px;
    font-size: 24px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    transition: top 0.3s;
}

.warning {
    width: 100%;
    height: 40px;
    background-color: var(--error);
}

.navWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}

.visible {
    top: 0;
}

.hidden {
    top: -70px;
}

.emptyDiv {
    width: 100%;
    height: 70px;
    background-color: #b1afb1;
}

.logoSection {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    margin-left: 15%;
}

.logo {
    width: 160px;
    margin-top: 7px;
}

.menu {
    z-index: 2;
    margin-right: 15%;
}

.ul {
    display: flex;
    list-style: none;
}

.item {
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 2.5rem;
}

.itemText {
    z-index: 10;
    text-decoration: none;
    color: var(--blue, #002950);
    font-family: var(--p03-24-bold-font-family);
    font-size: var(--p03-24-bold-font-size);
    font-style: var(--p03-24-bold-font-style);
    font-weight: var(--p03-24-bold-font-weight);
    letter-spacing: var(--p03-24-bold-letter-spacing);
    line-height: var(--p03-24-bold-line-height);
}

.highlighter {
    z-index: -1;
    position: absolute;
    top: 13px;
    left: -5px;
    width: calc(100% + 10px);
    height: 14px;
    background-color: var(--yellow, #FFD000);
    border-radius: 3px;
    animation: loadFromLeftToRight 0.6s forwards;
}

.hide {
    display: none
}

@keyframes loadFromLeftToRight {
    0% {
        width: 0;
    }

    100% {
        width: calc(100% + 10px);
    }
}

@media only screen and (max-width: 900px) {
    .desktop {
        display: none
    }

    .mobile {
        display: block
    }


    .content {
        justify-content: space-between;
    }

    .rightSide {
        width: 40%
    }

    .navWrapper, .wrapper {
        width: 100%;
        height: 48px;
    }

    .emptyDiv {
        height: 48px;
    }

    .logoSection {
        font-size: 17px;
        margin-left: 5%;
    }

    .logo {
        width: 130px;
        margin-top: 4px;
    }

    .navbar {
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        color: var(--blue);
        padding: 10px;
        margin-right: 5%;
    }

    .menuToggle {
        cursor: pointer;
        display: flex;
        flex-direction: column;
    }

    .line {
        width: 30px;
        height: 3px;
        background-color: var(--blue);
        margin: 3px 0;
        transition: transform 0.3s, opacity 0.3s;
    }

    .open .line:nth-child(1) {
        /* transform: rotate(-45deg) translate(-5px, 6px); */
        transform: rotate(-45deg) translate(-5px, 7px);
    }

    .open .line:nth-child(2) {
        opacity: 0;
    }

    .open .line:nth-child(3) {
        /* transform: rotate(45deg) translate(-5px, -6px); */
        transform: rotate(45deg) translate(-6px, -7px);
    }

    .menuContent {
        position: absolute;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        top: 48px;
        right: 0;
        width: 50%;
        max-width: 300px;
        height: 400px;
        background-color: white;
        color: var(--blue);
        font-size: 17px;
        padding: 20px;
        padding-top: 5rem;
        transform-origin: top right;
        transform: translateX(100%);
        transition: transform 0.3s;
        box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 5px;
    }

    .itemText {
        color: var(--Blue, #002950);
        font-family: Montserrat;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .open .menuContent {
        transform: translateX(0);
    }
}

@media only screen and (max-width: 1500px) and (min-width: 900px) {
    .logoSection {
        margin-left: 5%;
    }

    .menu {
        margin-right: 5%;
    }
}