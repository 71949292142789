.funFactSection {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-color: var(--blue);
}

.funFactWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 75%;
    margin-top: 33px;
}

.logoSection {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
}

.logo {
    width: 100px;
}

.text {
    color: var(--White, #FFF);
    font-family: Montserrat;
    font-size: 14px;
    margin-top: 35px;
}

.span {
    font-family: Montserrat;
    color: var(--Yellow, #FFD000);
    font-size: 14px;
}

.funFact {
    margin-bottom: 150px;
}

.buttonWrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 80px;
}