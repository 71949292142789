.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 35rem; */
    /* height: 40rem; */
    width: 100%;
    background-color: var(--blue);
    padding-bottom: 2rem;
}

.wrapper {
    display: flex;
    width: 70%;
    height: 90%;
}

/* ////////////////////////////////// Left section ////////////////////////// */

.leftSection {
    width: 50%;
    height: 100%;
}

.logoSection {
    margin-top: 1.75rem;

}

.logo {
    width: 300px;
}

.slogan {
    color: var(--Yellow, #FFD000);
    font-size: 32px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.6px;
    font-family: Montserrat;
}

.linksWrapper {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
}

.leftLinks {
    display: flex;
    flex-direction: column;
}

.socialWrapper {
    margin-top: 2rem;
}

.socialButton {
    width: 34;
    height: 34;
    margin-right: 1rem;
}

.socialLink {
    position: relative;
    height: 60px;
    padding: 0
}

.rightLinks {
    display: flex;
    flex-direction: column
}

.link {
    text-decoration: unset;
    cursor: pointer;
    color: var(--White, #FFF);
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.32px;
    font-family: Montserrat;
    margin-top: 1rem;
}

.reserved {
    color: var(--White, #FFF);
    font-size: 12px;
    margin-top: 2rem;
}

/* ////////////////////////////////// Right section ////////////////////////// */
.rightSection {
    display: flex;
    justify-content: right;
    width: 50%;
    height: 100%;
}

.formSection {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.formTitle {
    color: var(--White, #FFF);
    font-size: 32px;
    font-weight: 600;
    font-family: Montserrat;
    margin: 1.75rem 0 20px 0;
}

.inputContact, .textAreaWrapper {
    color: var(--Gray-dark, black);
    font-size: 16px;
    width: 100%;
    height: 50px;
    font-family: Montserrat;
    border-radius: 15px;
    background: var(--White, #FFF);
    resize: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding-left: 1rem;
    border: none;
}

.inputContact:focus {
    outline: none;
}

.textAreaWrapper {
    height: 100px;
    padding: 1rem 1rem 1.3rem 1rem;
}

.textAreaSection {
    position: relative;
}

.textareaContact {
    all: unset;
    z-index: 20;
    height: 100%;
    width: 100%;
    word-wrap: break-word;
}

.contentLength {
    position: absolute;
    bottom: 13px;
    right: 10px;
    text-align: right;
    color: var(--gray-dark);
}

.inputRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.inputContactRow {
    width: 49% !important;
}

.formMessage {
    font-family: Montserrat;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 0 20px;
}

.errorMessage {
    color: var(--Error, #C14C50);
}

.successMessage {
    color: var(--Success, #279869);
}

.checkboxSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0 0 1rem;
}

.checkboxWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    margin-bottom: 10px;
    margin-left: 6px;
    margin-right: 12px;
}

.customCheckbox input[type="checkbox"] {
    display: none;
}

.customCheckbox .checkmark {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border: 2px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    outline: 1px solid var(--yellow);
    margin-right: 5px;
}

.customCheckbox input[type="checkbox"]:checked+.checkmark {
    border: 2px solid var(--blue);
    background-color: var(--yellow);
    border-radius: 4;
}

.customCheckbox {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: var(--Gray-dark, #707070);
    font-size: 12px;
    font-family: Montserrat;
}

.checkboxText {
    width: 100%;
    margin: 0 0 0 5px;
}

.sendButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36.366px;
    height: 36.977px;
    cursor: pointer;
    border-radius: 36.977px;
    background: var(--Yellow, #FFD000);
}

.sendButton:hover {
    scale: 1.2
}

.desktop {
    display: block
}

.mobile {
    display: none
}

.sendingForm {
    display: inline-block;
    transform: translateZ(1px);
}

.sendingForm>div {
    animation: sendingForm 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

@keyframes sendingForm {
    0%, 100% {
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(180deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    100% {
        transform: rotateY(360deg);
    }
}





@media only screen and (max-width: 900px) {

    .desktop {
        display: none
    }

    .mobile {
        display: block
    }

    .footer {
        height: unset;
    }

    .wrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 30px;
    }

    .slogan {
        font-size: 22.56px;
        margin: 36px 0 24px 0;
    }

    .leftSection {
        width: 100%;
    }

    .logo {
        width: 200px;
    }

    .rightSection {
        width: 100%;
    }

    .checkboxSection {
        margin: 10px 0 15px 0;
    }

    .formSection {
        width: 100%;
    }

    .formTitle {
        font-size: 22.56px;
        margin-top: 0px;
    }

    .linksTitle {
        color: var(--Yellow, #FFD000);
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .linksWrapper {
        display: flex;
        flex-direction: column;
    }

    .socialWrapper {
        margin-top: 1rem;
    }

    .link {
        margin: 0
    }

    .reserved {
        margin-bottom: 1rem;
    }

    .rightLinks {
        display: flex;
        flex-direction: column;
    }

    .rightLinks.desktop {
        display: none;
    }

}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
    .wrapper {
        width: 90%;
    }

}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .wrapper {
        width: 90%;
    }

}