* {
    margin: 0;
    padding: 0;
}

.rankSection {
    width: 100%;
    min-height: 100vh;
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('/public/background-map-blue.webp');
}

.companySection {
    width: 100%;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.companyImg {
    width: 150px;
}

.companyName {
    margin: 10px;
    color: white;
    font-family: Montserrat;
}

.rankWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 3rem;
    margin-top: 1.5rem;
}

.mobileTopSection {
    border: 2px solid var(--yellow);
    border-radius: 15px;
    width: calc(100% - 63px);
    padding: 0 15px;
    margin-right: 20px;
    margin-left: 13px;
}

.mobileTopLineWrapper {
    margin: unset !important;
    margin-bottom: -1px !important;
}

.text {
    margin: 5%;
    color: var(--yellow)
}

.titleWrapper {
    color: var(--Gray-mid, #D0D0D0);
    font-family: Montserrat;
    font-size: 14px;
    margin-top: 38px;
}

.title {
    font-size: 14px
}

.topWrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    width: 100%;
}

.top1Img {
    position: absolute;
    top: -10px
}

.top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2.1rem;
    margin-top: 2.5rem;
}

.top1 {
    display: flex;
    justify-content: center;
    width: 60px;
    height: 86px;
    border: 1px solid var(--yellow, #FFD000);
    border-radius: 10px;
    background-color: var(--yellow, #FFD000);
    color: var(--Blue, #002950);
    font-family: Montserrat;
    font-size: 32px;
    margin: auto;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 3px solid var(--yellow, #FFD000);
    border-radius: 10px;
    color: var(--yellow, #FFD000);
    font-family: Montserrat;
    font-size: 32px;
    margin: auto;
    border-bottom: unset;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.top2Wrapper, .top3Wrapper, .clm {
    width: 100%;
}

.top2Wrapper, .top3Wrapper {
    margin-top: 2.1rem;
}

.teamNameTop {
    display: none;
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 9px;
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.points, .time {
    color: #AEAEAE;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* /////////////////////////////////////////// rest /////////////////////////////// */

.restWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: -2px;
}

.lineWrapper {
    width: 100%;
}

.line {
    display: grid;
    font-style: normal;
    grid-template-columns: 5% 55% 15% 17%;
    justify-content: space-between;
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    border-bottom: 1px solid #AEAEAE;
    margin-right: 35px;
    margin-left: 28px;
    padding: 10px 0px;
}

.cl2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cl3, .cl4 {
    align-items: center;
    color: #AEAEAE;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.active {
    background-color: #FFD000;
    margin-top: -1px;
}

.active .line p {
    color: var(--blue, #002950) !important;
}

.active .line {
    border-bottom: none;
}

.tableTitleRow, .top2Img, .mobilePointsWrapper {
    display: none;
}

.time, .points {
    display: block
}

@media only screen and (min-width: 1200px) {
    .mobileTopSection {
        display: none;
    }

    .rankWrapper {
        margin-bottom: 10rem;
    }

    .titleWrapper {
        color: #FFF;
        font-size: 28px;
    }

    .title {
        font-size: 28px
    }

    .restWrapper {
        align-items: center;
        margin-top: 50px;

    }

    .topWrapper {
        margin-top: 2rem;
    }

    .top {
        margin-top: 2rem;
        width: 35%;
        column-gap: 4rem;
    }

    .top1Img {
        display: none;
    }

    .top2Img {
        display: block;
        width: 60%;
        margin-top: -6rem;
    }

    .time, .points {
        display: none
    }

    .pointsMobile, .timeMobile {
        color: #FFF;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 6px;
    }

    .top2Wrapper, .clm, .top3Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
    }

    .mobilePointsWrapper {
        display: flex;
        justify-content: center;
    }

    .teamNameTop {
        display: block;
        text-align: center;
        max-width: 140px;
        color: #FFF;
        font-size: 28px;
    }

    .top1, .box {
        width: 96px;
        height: 86px;
        border-radius: 20px;
        font-size: 48px;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 20px;
        border: 3px solid var(--yellow, #FFD000);
        border-radius: 10px;
    }

    .tableTitleRow {
        display: block;
        background: var(--yellow, #FFD000) !important;
    }

    .tableTitleRow .line p {
        font-size: 14px;
        color: #002950 !important;
        border: 3px solid var(--Yellow, #FFD000);
    }

    .lineWrapper {
        width: 60%;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(3.049999952316284px);
        margin-bottom: 10px;
    }

    .line {
        border-bottom: none;
    }

    .line p, .active .line p {
        color: white !important;
    }

    .active {
        border: 3px solid var(--Yellow, #FFD000);
    }
}