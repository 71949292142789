.generalBackgroundSection {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-color: var(--blue);
    background-image: url("/public/background-map-blue-mobile.webp");
    background-repeat: repeat;
}

.generalBackgroundWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 75%;
    margin-top: 33px;
}

.logo {
    width: 100px;
}

.title {
    display: inline-block;
    color: var(--Yellow, #FFD000);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    margin-top: 43px;
}

.titleUnderline {
    width: 110%;
    height: 2px;
    background-color: var(--yellow);
    margin: 6px 0 0 -5%;
}

.textWrapper {
    margin-top: 28px;
}

.bold {
    font-weight: bold;
}

.warningDiv {
    margin-bottom: 150px;
    color: var(--Error, #C14C50);
    font-family: Montserrat;
    font-size: 14px;
}

.text {
    font-family: Montserrat;
    font-size: 14px;
    color: var(--White, #FFF);
}

.buttonWrapper {
    position: absolute;
    bottom: 80px;
}