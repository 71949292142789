.text {
    width: 70%;
    color: var(--blue);
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 600;
}

/* ////////////////////////////////////////////// Highlighter /////////////////////////////////////*/
.highlighterSpan {
    position: relative;
    display: inline-block;
    z-index: 0;
}

.highlighter {
    z-index: -1;
    position: absolute;
    bottom: 3px;
    left: -5px;
    height: 23px;
    background-color: var(--yellow);
    border-radius: 10px;
    animation: loadFromLeftToRight 0.6s forwards;
}

.hide {
    display: none;
}

@keyframes loadFromLeftToRight {
    0% {
        width: 0;
    }

    100% {
        width: calc(100% + 10px);
    }
}


@media only screen and (max-width: 600px) and (min-width: 300px) {

    .text {
        font-size: 22.56px !important;
        width: 100%;
    }

    .highlighter {
        height: 16px;
        bottom: -1px;
        border-radius: 5px;
    }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
    .text {
        width: 90%;
        font-size: 22.56px;
    }

    .highlighter {
        height: 16px;
        bottom: -1px;
        border-radius: 5px;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
    .text {
        width: 90%;
    }

}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .text {
        width: 90%;
    }
}