.textWrapper {
    width: 70%;
}

.title {
    color: var(--Yellow, #FFD000);
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 600;
    margin: 7rem 0 1rem 0;
}

.text {
    color: var(--White, #FFF);
    font-family: Montserrat;
    font-size: 24px;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
    .textWrapper {
        padding: 0 30px;
        width: 100%;
        margin-bottom: 5rem;
    }

    .title {
        font-size: 22.56px;
        margin-top: 8rem
    }

    .textWrapper .text {
        font-size: 17px;
        margin-bottom: 24px
    }

    .pTitleMobile {
        font-size: 22.56px;
        color: var(--Yellow, #FFD000);
        font-weight: 600;
        margin-bottom: 12px;
        display: block;
    }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
    .textWrapper {
        padding: 0 30px;
        width: 100%;
        margin-bottom: 5rem;
    }

    .title {
        font-size: 22.56px;
    }

    .textWrapper .text {
        font-size: 17px;
        margin-bottom: 24px
    }

    .pTitleMobile {
        font-size: 22.56px;
        color: var(--Yellow, #FFD000);
        font-weight: 600;
        margin-bottom: 12px;
        display: block;
    }
}


@media only screen and (max-width: 1200px) and (min-width: 900px) {
    .textWrapper {
        width: 90%;
        margin: 8rem 0 3rem 0;
    }

}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .textWrapper {
        width: 90%;
    }
}