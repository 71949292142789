.endSection {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-image: url("/public/background-map-blue-mobile.webp");
    background-repeat: repeat;
}

.endWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 75%;
    margin-top: 33px;
}

.aButton {
    text-decoration: none;
}

.logoSection {
    width: 100%;
}

.logo {
    width: 100px;
}

.title {
    display: inline-block;
    color: var(--Yellow, #FFD000);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    margin-top: 4rem;
    margin-bottom: 0px;
}

.text {
    color: var(--White, #FFF);
    font-family: Montserrat;
    font-size: 14px;
    margin-top: 2.5rem;
}

.statistic {
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    column-gap: 1rem;
    width: 100%;
    margin-top: 3rem;

}

.importantStats {
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    column-gap: 1rem;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 180px
}

.indicators {
    color: var(--White, #FFF);
    font-family: Montserrat;
    font-size: 14px;
    margin-top: 49px;
    margin: 0;
}


.bold {
    font-weight: bold;
    color: var(--Yellow, #FFD000);
}

.buttonWrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    bottom: 80px;
}

.endStageWave {
    display: none;
}

.anc {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

@media only screen and (min-width: 600px) {
    .endSection {
        background-image: url("/public/background-map-blue.webp");

    }

    .endWrapper {
        margin-top: -100px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .endStageWave {
        position: relative;
        display: block;
        width: 100%;
    }

    .test1 {
        height: 1px;
        width: 100%;
    }

    .title {
        font-size: 32px;
    }

    .text {
        font-size: 24px;
    }

    .indicators {
        font-size: 24px;
    }

    .statistic {
        border-radius: 15px;
        border: 5px solid var(--Yellow, #FFD000);
        background: var(--Blue, #002950);
        backdrop-filter: blur(13.850000381469727px);
        padding: 38px 50px
    }

    .statistic {
        width: 60%;
    }

    .buttonWrapper, .text {
        width: calc(60% + 100px);
    }

    .buttonWrapper {
        margin-top: 10rem;
        position: relative;
    }

    .test {
        position: relative;
    }
}

@media only screen and (min-width: 1000px) {
    .statistic {
        width: 70%;
    }

    .buttonWrapper, .text {
        width: calc(70% + 100px);
    }
}

@media only screen and (min-width: 1450px) {
    .statistic {
        width: 40%;
    }

    .buttonWrapper, .text {
        width: calc(40% + 100px);
    }

    .logoSection {
        display: flex;
        justify-content: center;
    }

}

@media only screen and (min-width: 2100px) {

    .endStageWave {
        top: -50px
    }

}