/* Blurred overlay with disabled interaction */
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    /* Blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: auto;
    /* Ensures interaction with popup is enabled */
    overflow: hidden;
}

/* Prevent clicks on background */
.popupOverlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

/* Popup content styling */
.popupContent {
    background: #fff;
    padding: 40px 59px 40px 59px;
    /* padding: 59px; */
    border-radius: 44px;
    max-width: 90%;
    width: 528px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
    font-family: Arial, sans-serif;
    position: relative;
    z-index: 10;


    max-height: 90vh;
    /* Ensure popup does not exceed viewport height */
    overflow-y: auto;
    /* Make popup content scrollable if needed */
    scrollbar-width: thin;
}

.title {
    font-size: 35px;
    font-family: 'Montserrat';
    line-height: 42.67px;
    margin-bottom: 40px;
    font-weight: 700;
}

.subtitle {
    font-size: 20px;
    font-family: 'Montserrat';
    line-height: 24.38px;
    margin-top: 30px;
}

.text {
    font-size: 20px;
    font-family: 'Montserrat';
    line-height: 24.38px;
    font-weight: 400;
}

.bold {
    font-weight: bold;
}

.btnWrapper {
    display: inline-block;
    margin-top: 30px
}

/* Close button styling */
.popupClose {
    position: absolute;
    top: 25px;
    right: 35px;
    background: none;
    border: none;
    font-size: 40px;
    color: #CCCCCC;
    cursor: pointer;
}

/* Button styling */
.popupButton {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background: #ff5733;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
}

.popupButton:hover {
    background: #c63e1a;
}

.popupList {
    list-style: none;
    padding-left: 0;
    counter-reset: item;
    margin-bottom: 30px;
}

.popupList li {
    counter-increment: item;
    position: relative;
    padding-left: 30px;
}

.popupList li::before {
    content: counter(item) '. ';
    position: absolute;
    margin-left: 7px;
    font-family: 'Montserrat';
    left: 0;
}

/* Responsive text adjustments */
@media (max-width: 768px) {
    .title {
        font-size: 23.67px;
        line-height: 28.85px;
        margin-bottom: 20px;
    }

    .popupContent {
        width: 80%;
        max-width: unset;
        padding: 25px 25px 15px 25px;
        border-radius: 35px;
    }

    .text, .subtitle {
        font-size: 18.94px;
        line-height: 23.08px;
    }

    .popupClose {
        font-size: 25px;
        top: 15px;
        right: 25px;
    }

    .btnWrapper {
        display: flex;
        justify-content: center;
    }
}