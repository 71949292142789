/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --black: rgba(18, 18, 18, 1);
  --blue: rgba(0, 41, 80, 1);
  --button-font-family: "Montserrat-Medium", Helvetica;
  --button-font-size: 20px;
  --button-font-style: normal;
  --button-font-weight: 500;
  --button-letter-spacing: 0px;
  --button-line-height: normal;
  --error: rgba(193, 76, 80, 1);
  --gray-dark: rgba(112, 112, 112, 1);
  --gray-light: rgba(225, 225, 225, 1);
  --gray-mid: rgba(208, 208, 208, 1);
  --h01-60-bold-font-family: "Montserrat", Helvetica;
  --h01-60-bold-font-size: 60px;
  --h01-60-bold-font-style: normal;
  --h01-60-bold-font-weight: 600;
  --h01-60-bold-letter-spacing: 0px;
  --h01-60-bold-line-height: normal;
  --h01-60-reg-font-family: "Montserrat", Helvetica;
  --h01-60-reg-font-size: 60px;
  --h01-60-reg-font-style: normal;
  --h01-60-reg-font-weight: 400;
  --h01-60-reg-letter-spacing: 0px;
  --h01-60-reg-line-height: normal;
  --h02-48-bold-font-family: "Montserrat", Helvetica;
  --h02-48-bold-font-size: 48px;
  --h02-48-bold-font-style: normal;
  --h02-48-bold-font-weight: 600;
  --h02-48-bold-letter-spacing: 0px;
  --h02-48-bold-line-height: normal;
  --h02-48-reg-font-family: "Montserrat", Helvetica;
  --h02-48-reg-font-size: 48px;
  --h02-48-reg-font-style: normal;
  --h02-48-reg-font-weight: 400;
  --h02-48-reg-letter-spacing: 0px;
  --h02-48-reg-line-height: normal;
  --h03-40-bold-font-family: "Montserrat", Helvetica;
  --h03-40-bold-font-size: 40px;
  --h03-40-bold-font-style: normal;
  --h03-40-bold-font-weight: 600;
  --h03-40-bold-letter-spacing: 0px;
  --h03-40-bold-line-height: normal;
  --h03-40-reg-font-family: "Montserrat", Helvetica;
  --h03-40-reg-font-size: 40px;
  --h03-40-reg-font-style: normal;
  --h03-40-reg-font-weight: 400;
  --h03-40-reg-letter-spacing: 0px;
  --h03-40-reg-line-height: normal;
  --label-14-font-family: "Montserrat", Helvetica;
  --label-14-font-size: 14px;
  --label-14-font-style: normal;
  --label-14-font-weight: 400;
  --label-14-letter-spacing: 0px;
  --label-14-line-height: normal;
  --label-16-font-family: "Montserrat", Helvetica;
  --label-16-font-size: 16px;
  --label-16-font-style: normal;
  --label-16-font-weight: 400;
  --label-16-letter-spacing: 0px;
  --label-16-line-height: normal;
  --link-32-font-family: "Montserrat", Helvetica;
  --link-32-font-size: 32px;
  --link-32-font-style: italic;
  --link-32-font-weight: 400;
  --link-32-letter-spacing: 0px;
  --link-32-line-height: normal;
  --p01-32-bold-font-family: "Montserrat-SemiBold", Helvetica;
  --p01-32-bold-font-size: 32px;
  --p01-32-bold-font-style: normal;
  --p01-32-bold-font-weight: 600;
  --p01-32-bold-letter-spacing: 0px;
  --p01-32-bold-line-height: normal;
  --p01-32-reg-font-family: "Montserrat-Regular", Helvetica;
  --p01-32-reg-font-size: 32px;
  --p01-32-reg-font-style: normal;
  --p01-32-reg-font-weight: 400;
  --p01-32-reg-letter-spacing: 0px;
  --p01-32-reg-line-height: normal;
  --p02-28-bold-font-family: "Montserrat-SemiBold", Helvetica;
  --p02-28-bold-font-size: 28px;
  --p02-28-bold-font-style: normal;
  --p02-28-bold-font-weight: 600;
  --p02-28-bold-letter-spacing: 0px;
  --p02-28-bold-line-height: normal;
  --p02-28-reg-font-family: "Montserrat-Regular", Helvetica;
  --p02-28-reg-font-size: 28px;
  --p02-28-reg-font-style: normal;
  --p02-28-reg-font-weight: 400;
  --p02-28-reg-letter-spacing: 0px;
  --p02-28-reg-line-height: normal;
  --p03-24-bold-font-family: "Montserrat-SemiBold", Helvetica;
  --p03-24-bold-font-size: 24px;
  --p03-24-bold-font-style: normal;
  --p03-24-bold-font-weight: 600;
  --p03-24-bold-letter-spacing: 0px;
  --p03-24-bold-line-height: normal;
  --p03-24-reg-font-family: "Montserrat-Regular", Helvetica;
  --p03-24-reg-font-size: 24px;
  --p03-24-reg-font-style: normal;
  --p03-24-reg-font-weight: 400;
  --p03-24-reg-letter-spacing: 0px;
  --p03-24-reg-line-height: normal;
  --success: rgba(39, 152, 105, 1);
  --white: rgba(255, 255, 255, 1);
  --yellow: rgba(255, 208, 0, 1);
}