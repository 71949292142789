.desktop {
    display: block;
}

.mobile {
    display: none;
}

.commentActionSection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.commentCard {
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    width: 400px;
    border-radius: 30px;
    border: 5px solid var(--Yellow, #FFD000);
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(1.399999976158142px);
    margin-right: 1.75rem;
}

.commentText {
    width: 100%;
    color: var(--blue, #002950);
    font-size: 24px;
    font-family: Montserrat;
    margin: 50px 45px;
}

.buttonSection {
    display: flex;
    flex-direction: row;
}

.buttonWrapper {
    display: flex;
    flex-direction: row;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 10rem 2rem auto;
}

.button, .inactiveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 0.75rem;
    flex-shrink: 0;
}

.button {
    cursor: pointer;
    width: 55px;
    height: 55px;
    background-color: var(--yellow);
}

.button:hover {
    scale: 1.1;
}

.inactiveButton {
    width: 51px;
    height: 51px;
    border: 2px solid var(--blue);
    background-color: transparent;
}

@media only screen and (max-width: 900px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .commentCard {
        border-radius: 15px;
        width: 270px;
    }

    .commentText {
        font-size: 17px;
        margin: 26px 24px;
    }

    .buttonSection {
        margin-top: 26px;
    }

    .button img, .inactiveButton img {
        width: 15px;
        height: 15px;
    }

    .button, .inactiveButton {
        cursor: unset
    }

    .button {
        width: 35px;
        height: 35px;
    }

    .button:hover {
        scale: unset;
    }

    .inactiveButton {
        width: 32px;
        height: 32px;
        border: 1px solid var(--blue);
    }
}