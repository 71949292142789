.tutorialWrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: var(--blue);
}

.stage {
    position: absolute;
    z-index: 500;
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.button {
    position: absolute;
    z-index: 5000;
    border: none;
    border-radius: 5px;
    width: 180px;
    height: 38px;
    font-size: 20px;
    color: var(--blue);
    background-color: var(--yellow);
    bottom: 50px;
    left: calc(50% - 90px);
    font-family: Montserrat;
    font-weight: 500;

}

@media only screen and (min-width: 650px) {
    .tutorialWrapper {
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .image {
        position: relative;
        width: 300px;
        height: 640px;
    }

}