.wrapper {
    position: relative;
    background: url('./../../../public/background.jpg');
    background-position: left top;
    background-size: 100%;
    overflow: hidden;
}

.heroBannerSection {
    z-index: 1;
    position: relative;
    overflow: hidden;
    width: 100%;
    /* height: 132vh; */
}

.introductionSection {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    top: -100px
}

.everyoneSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--blue);
    /* clip-path: path('M-132.84 763.699C-57.9314 832.55 112.033 772.662 213.748 772.662C315.463 772.662 315.463 778.905 417.178 778.905C518.893 778.905 518.893 775.24 620.608 775.24C722.323 775.24 722.323 771.813 824.038 771.813C925.753 771.813 925.753 774.348 1027.47 774.348C1129.18 774.348 1129.18 753.921 1230.9 753.921C1332.61 753.921 1332.61 770.421 1434.33 770.421C1536.04 770.421 1536.04 759.164 1637.76 759.164C1739.47 759.164 1739.47 759.881 1841.2 759.881C1942.92 759.881 2075.01 835.987 2152.67 770.247C2218.36 714.645 2153.2 674.422 2153.2 588.332C2153.2 502.23 2148.84 502.23 2148.84 416.129C2148.84 330.028 2210.92 312.69 2148.84 253.074C2074.97 182.157 1942.55 235.54 1840.84 223.978C1739.12 212.416 1740.6 199.353 1638.89 187.78C1537.17 176.218 1538.05 168.419 1436.34 156.857C1334.62 145.295 1331.56 172.259 1229.84 160.697C1128.13 149.134 1130.12 131.634 1028.4 120.071C926.688 108.509 927.906 97.8282 826.191 86.2553C724.476 74.6932 720.725 107.683 619.01 96.1097C517.295 84.5476 519.513 65.0019 417.798 53.4398C316.083 41.8776 313.811 61.8258 212.096 50.2637C110.381 38.7016 -40.1255 -39.1006 -119.871 25.1272C-192.66 83.7427 -146.471 106.095 -146.471 199.581C-146.471 293.068 -149.439 293.068 -149.439 386.555C-149.439 480.041 -119.915 479.922 -140.732 571.059C-163.462 670.517 -201.661 700.439 -132.851 763.677L-132.84 763.699Z'); */
    clip-path: url(#tilted-mask);
    padding: 100px 0;
}

.flowImage {
    width: 100%;
    margin-bottom: -5rem;
}

.moreInfoSection {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 70vh; */
    height: 800px;
    width: 100%;
    /* clip-path: path("M-57.99 923.179C10.92 986.479 157.274 931.419 250.844 931.419C344.414 931.419 344.414 937.159 437.984 937.159C531.554 937.159 531.554 933.789 625.124 933.789C718.694 933.789 718.694 930.639 812.264 930.639C905.834 930.639 905.834 932.969 999.404 932.969C1092.97 932.969 1092.97 914.189 1186.54 914.189C1280.11 914.189 1280.11 929.359 1373.68 929.359C1467.25 929.359 1467.25 919.009 1560.82 919.009C1654.39 919.009 1654.39 919.669 1747.97 919.669C1841.55 919.669 1906.06 989.639 1977.5 929.199C2037.93 878.079 1977.99 669.23 1977.99 590.08C1977.99 510.92 1973.98 510.92 1973.98 431.76C1973.98 352.6 2018.97 287.482 1927.04 232.672C1859.09 167.472 1841.21 216.552 1747.64 205.922C1654.07 195.292 1655.43 183.282 1561.86 172.642C1468.29 162.012 1469.1 154.842 1375.53 144.212C1281.96 133.582 1279.14 158.372 1185.57 147.742C1092 137.112 1093.83 121.022 1000.26 110.392C906.694 99.7615 907.814 89.9415 814.244 79.3015C720.674 68.6715 717.224 99.0015 623.654 88.3615C530.084 77.7315 532.124 59.7616 438.554 49.1315C344.984 38.5015 342.894 56.8415 249.324 46.2115C155.754 35.5815 27.2999 -35.9484 -46.0601 23.1016C-113.02 76.9916 -70.5303 146.72 -70.5303 232.67C-70.5303 318.62 -73.2603 318.62 -73.2603 404.57C-73.2603 490.52 -46.1003 490.41 -65.2502 574.2C-86.1602 665.64 -121.3 865.019 -58 923.159L-57.99 923.179Z"); */
    clip-path: url(#tilted-mask);
    background-color: var(--blue);
}

.commentSection {
    overflow: hidden;
    padding: 5rem 0rem 10rem 15%;
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}

@media only screen and (max-width: 600px) {
    .wrapper {
        overflow: hidden;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .heroBannerSection {
        height: 650px;

    }

    .introductionSection {
        top: unset;
        width: calc(100% - 60px);
        padding: 0 30px !important;
        margin-top: -30px;
        margin-bottom: 15px;
    }

    .everyoneSection {
        clip-path: url(#tilted-mask-mobile);
        padding: 0 30px !important;
        width: calc(100% - 60px);
        height: 650px;
    }

    .quickFactsSection {
        align-items: center;
        width: calc(100%-60px);
        padding: 0 30px !important;
        margin-top: 0;
        margin-bottom: 0;
    }

    .moreInfoSection {
        clip-path: url(#tilted-mask-mobile);
        height: unset;
    }

    .commentSection {
        padding: 3rem 0rem 3rem 30px;
    }
}

@media only screen and (max-width: 360px) and (min-width: 300px) {
    .everyoneSection {
        height: 760px;

    }
}

@media only screen and (max-width: 600px) and (min-width: 480px) {
    .heroBannerSection {
        height: 850px;
    }

    .introductionSection {
        margin-top: -70px;
    }


}

@media only screen and (max-width: 900px) and (min-width: 600px) {


    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .introductionSection {
        top: -50px;
    }

    .everyoneSection {
        clip-path: url(#tilted-mask-mobile);
        padding: 0 5% !important;
        width: 90%;
        height: 590px;
    }

    .flowImageSection {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .flowImage {
        position: relative;
        top: -2px;
        width: 50%;
    }

    .moreInfoSection {
        clip-path: url(#tilted-mask-mobile);
        height: unset;
    }

    .commentSection {
        padding-bottom: 5rem;
        padding-left: 5%
    }
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
    .introductionSection {
        top: -50px;
    }

    .flowImage {
        position: relative;
        width: 126%;
        left: -13%
    }

    .moreInfoSection {
        height: 1000px;
    }

    .commentSection {
        padding-left: 5%
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {

    .flowImage {
        position: relative;
        width: 116%;
        left: -8%
    }

    .commentSection {
        padding-left: 5%
    }

}