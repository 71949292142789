.contentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 50vh;
    background-color: white;
    border-radius: 30px;
}

.modal {
    z-index: 2;
    position: fixed;
    align-items: center;
    width: 90%;
    bottom: -10%;
    border-radius: 30px;
    background-color: white;
    transition: transform 0.3s ease-in-out;
    transform: translateY(90%);
}

.modal.show {
    transform: translateY(0);
}

.backdrop {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
}

.textWrapper {
    width: 80%;
}

.text {
    font-size: 14px;
    font-family: Montserrat;
    color: var(--blue, #002950);
    margin-top: 19px;
}

.buttonWrapper {
    display: flex;
    margin-top: 2rem;
}