.catalogueWrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--blue);
    padding-top: 53px;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-bottom: 10rem;
    background-image: url('./../../../public/background-map-blue.webp');
    clip-path: url(#gentle-wave-clip-path);
    overflow: hidden;
}

.title h2 {
    font-size: unset;
    font-weight: unset;
    font-style: unset;
}

.seoTitle {
    display: none;
}

.howToPlaySection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1500px;
    background-color: white;
    clip-path: url(#bottom-v-1);
}

.howToPlayWrapper {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-left: 15%;
    width: 85%;
}

.textWrapper {
    width: 40%;
}

.howToPlaySection .title {
    width: 1149px;
    color: var(--Blue, #002950);
    font-size: 40px;
    font-weight: 600;
    font-family: Montserrat;
    margin-bottom: 16px;

}

.text {
    margin-bottom: 1rem;
}

.howToPlaySection .text {
    color: var(--blue, #002950);
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400;
}

.spanLink a {
    cursor: pointer;
    color: var(--yellow, #FFD000);
    font-family: Montserrat;
    font-size: 24px;
    font-style: italic;
    text-decoration-line: underline;
}

.appMock {
    scale: 1;
    margin-top: 8rem;
    margin-left: 2rem;
}

/* //////////////////////////////////////// More information about the game ///////////////////// */
.moreInfoSection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
}

.moreInfoWrapper {
    width: 70%;
}

.moreInfoSection .title {
    color: var(--Yellow, #FFD000);
    font-size: 40px;
    font-weight: 600;
    font-family: Montserrat;
    margin-bottom: 16px;
}

.moreInfoSection .text {
    color: var(--White, #FFF);
    font-size: 24px;
    font-family: Montserrat;
}

/* ////////////////////////////////////////// Background window /////////////////// */
.backgroundWindow {
    width: 100%;
    height: 40vh;
}

/* ///////////////////////////////////////// Footer wave mask //////////////////////*/
.footerWave {
    width: 100%;
    height: 10rem;
    background-color: var(--blue);
    clip-path: url(#top-wave-clip-path);
    margin-bottom: -3px;
}

/* /////////////////////////////////////////// Highlighter ///////////////////////////// */
.highlighterSpan {
    display: inline-block;
    position: relative;
}

.highlighter {
    z-index: -1;
    position: absolute;
    bottom: 3px;
    left: -5px;
    height: 23px;
    background-color: var(--yellow);
    border-radius: 10px;
    animation: loadFromLeftToRight 0.6s forwards;
}

.hide {
    display: none
}

@keyframes loadFromLeftToRight {
    0% {
        width: 0;
    }

    100% {
        width: calc(100% + 10px);
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {

    .howToPlaySection {
        height: unset;
        padding-top: 22rem;
        padding-bottom: 10rem;
    }

    .howToPlayWrapper {
        flex-direction: column;
        width: 90%;
        margin-left: unset;
    }

    .textWrapper {
        width: 100%;
    }

    .moreInfoWrapper {
        width: 90%;
    }
}

@media only screen and (max-width: 1200px) {
    .howToPlaySection {
        height: unset;
        padding-top: 22rem;
        padding-bottom: 10rem;
    }

    .howToPlayWrapper {
        flex-direction: column;
        width: 90%;
        margin-left: unset;
    }

    .textWrapper {
        width: 100%;
    }

    .moreInfoWrapper {
        width: 90%;
    }

    .spanLink a {
        font-family: Montserrat;
        font-size: 24px;
        /* text-decoration: none; */
    }

    .blueLink a {
        color: var(--blue---light, #347EC1);
    }

    .links {
        text-decoration: none;

    }

    .howToPlaySection {
        height: unset;
        padding-top: 6rem;
        padding-bottom: 10rem;
        clip-path: url(#bottom-v-1-mobile);
    }

    .catalogueWrapper {
        clip-path: url(#gentle-wave-clip-path-mobile);
    }

    .footerWave {
        clip-path: url(#top-wave-clip-path-mobile);
    }
}

@media only screen and (max-width: 900px) {

    .catalogueWrapper {
        padding-bottom: 5rem;
        padding-top: 1.5rem;
    }

    .howToPlayWrapper {
        flex-direction: column;
        width: 90%;
        margin-left: unset;
    }

    .textWrapper {
        width: 100%;
    }

    .howToPlaySection .title {
        font-size: 22.5px;
        margin-bottom: 12px;
    }

    .howToPlaySection .text {
        font-size: 17px;
        margin-bottom: 24px;
    }

    .moreInfoWrapper {
        width: 90%;
    }

    .pTitle {
        display: block;
        color: var(--Blue, #002950);
        font-family: Montserrat;
        font-size: 22.56px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .spanLink a {
        color: var(--Blue---light, #347EC1);
        font-family: Montserrat;
        font-size: 17px;
    }

    .appMock {
        width: 100%;
        margin-top: 1rem;
        margin-left: unset;
    }

    .moreInfoMobileTitle {
        display: block;
        color: var(--Yellow, #FFD000);
        font-size: 22.56px;
        font-weight: 600;
        margin: 24px 0 12px 0;
    }

    .moreInfoSection .title {
        font-size: 22.5px;
        margin-bottom: 12px;
    }

    .moreInfoSection .text {
        font-size: 17px
    }

    .moreInfoSection .spanLink a {
        color: var(--Yellow, #FFD000);
    }

    .highlighter {
        height: 16px;
        bottom: -1px;
        border-radius: 5px;
    }
}