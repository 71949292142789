.wrapper {
    position: relative;
    background: url('./../../../../public/background.jpg');
    background-position: left top;
    background-size: 100%;
}

/* ////////////////////////////////////////// Hero banner section ////////////////////////////// */
.video {
    position: relative;
    width: 110%;
    left: -5%;
    top: 0px;
    z-index: 1;
    clip-path: url(#hero-banner-video-mask);
    min-width: 2300px;
    left: 50%;
    transform: translateX(-50%);
}

.videoBackground {
    position: absolute;
    z-index: -3 !important;
}

.videoWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.sloganSection {
    z-index: 2;
    position: absolute;
    padding: 10rem 0 0 15%;
}

.slogan {
    /* width: 602px; */
    width: 700px;
    color: var(--blue);
    font-family: var(--h02-48-bold-font-family);
    font-size: var(--h02-48-bold-font-size);
    font-style: var(--h02-48-bold-font-style);
    font-weight: var(--h02-48-bold-font-weight);
    letter-spacing: var(--h02-48-bold-letter-spacing);
    line-height: var(--h02-48-bold-line-height);
}

.buttonWrapper {
    z-index: 2;
    position: absolute;
    top: 270px;
    margin: 2.5rem 0 0 15%;
}

.mobile {
    display: none !important;
}

.desktop {
    display: block !important;
}

@media only screen and (max-width: 600px) {
    .wrapper {
        overflow: hidden;
    }

    .desktop {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }

    .video {
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 520px;
        clip-path: url(#hero-banner-video-mask-mobile);
    }


    .heroBannerSection {
        height: 650px;

    }

    .sloganSection {
        padding: 44px 0 0 30px !important;
    }

    .slogan {
        font-size: 22.56px !important;
        width: 312px !important;
    }

    .buttonWrapper {
        top: 130px;
        margin-left: 30px !important;
        margin-top: 24px !important;
    }


}

@media only screen and (max-width: 600px) and (min-width:480px) {
    .video {
        top: -60px;
        min-width: 700px;
    }

}

@media only screen and (max-width: 900px) and (min-width:600px) {


    .video {
        min-width: 1000px;
    }

    .slogan {
        font-size: 35.627px;
        width: 70%;
    }

    .sloganSection {
        padding-left: 5%;
        padding-top: 100px;
    }

    .buttonWrapper {
        margin-left: 5%;
    }

}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
    .video {
        min-width: 1300px;
    }

    .slogan {
        font-size: 35px;
    }

    .sloganSection {
        padding-left: 5%;
    }

    .buttonWrapper {
        margin-left: 5%;
        margin-top: 25px;
    }

}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .video {
        min-width: 1800px;
    }

    .sloganSection {
        padding-left: 5%;
    }

    .buttonWrapper {
        margin-left: 5%;
    }
}