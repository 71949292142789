button {
    user-select: none;
    /* Standard syntax */
    -webkit-user-select: none;
    /* Safari/Chrome, other WebKit */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.button {
    background: none;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    height: 38px;
    padding: 4.5px 36px 4.5px 36px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.group {
    margin: auto;
    align-items: center;
    text-align: center;
    display: flex;
}

.buttonArrow {
    height: 17px;
    width: 22px;
    color: green;
}

.textV1, .textV2, .textV3, .textV8 {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.textV1, .textV2, .textV9, .textV10 {
    margin-right: 10px;
}

.textV4, .textV5, .textV9, .textV10 {
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    color: var(--White, #FFF);
}

.textV1, .textV9 {
    color: var(--blue, #002950);
}

.textV6, .textV7 {
    text-align: center;
    font-size: 14px;
    font-family: Montserrat;
    color: var(--blue, #002950);
}

.textV7 {
    color: #8D8D8D !important;
}

.btnV1, .btnV9 {
    border: 2px solid var(--blue);
}

.btnV1:hover, .btnV1:hover .textV1 {
    color: white;
    background-color: var(--blue);
}

.btnV1:hover .buttonArrow {
    filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(3211%) hue-rotate(206deg) brightness(114%) contrast(106%);
}

.btnV2, .btnV3, .btnV10 {
    border: 2px solid var(--yellow);
    color: white
}

.btnV2:hover {
    color: black;
    background-color: var(--yellow);
}

.btnV2:hover .buttonArrow {
    filter: brightness(0) saturate(100%) invert(0%) sepia(8%) saturate(7479%) hue-rotate(349deg) brightness(90%) contrast(97%);
}

.btnV4 {
    height: inherit !important;
    border: 2px solid var(--yellow);
    color: white;
    padding: 9px 20px !important;
}

.btnV5 {
    border: 2px solid transparent;
    padding: 9px 20px !important;
}

.btnV6 {
    height: 25px;
    border-radius: 5px;
    border: 2px solid var(--Yellow, #FFD000);
    padding: 1px 22px 1px 22px !important;
}

.btnV7 {
    border: none !important;
    height: 25px;
}

.btnV8 {
    color: white;
    border: 2px solid transparent;
    padding: 1px 22px 1px 22px !important;
}

.btnV8:hover {
    border: 2px solid var(--Yellow, #FFD000);
}

.btnV9, .btnV10 {
    padding: 8px 20px !important;
}

.btnV11 {
    height: inherit !important;
    border: 2px solid var(--yellow);
    color: var(--blue, #002950);
    background-color: var(--Yellow, #FFD000);
}