.desktop {
    display: none;
}

.startStageSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-image: url("/public/background-map-blue-mobile.webp");
    background-repeat: repeat;
}

.startStageWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 75%;
    margin-top: 33px;
}

.logoSection {
    color: var(--Yellow, #FFD000);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    width: 100px;
}

.logo {
    width: 100px;
}

.riddleTitle {
    display: inline-block;
    color: var(--Yellow, #FFD000);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    margin-top: 25px;
}

.titleUnderline {
    width: 110%;
    height: 2px;
    background-color: var(--yellow);
    margin: 6px 0 0 -5%;
}

.textWrapper {
    margin-top: 28px;
}

.text {
    font-family: Montserrat;
    font-size: 14px;
    color: var(--White, #FFF);
    margin-bottom: 1rem;
}

.inputWrapper {
    margin-top: 26px;
    width: 100%;
    margin-bottom: 2rem;
}

.teamNameInput {
    width: 90%;
    height: 31px;
    font-family: Montserrat;
    font-size: 14px;
    color: white;
    border: 1px solid var(--Gray-dark, #707070);
    background-color: transparent;
    border-radius: 5px;
    padding-left: 20px;
}

.teamNameInput:focus {
    outline: 1px solid white;
}

.error {
    color: var(--error);
    border-color: var(--error);
}

.error::placeholder {
    color: var(--error);
}

.inputError {
    color: var(--error);
    font-style: italic;
    font-size: 14px
}

.warning {
    width: 100%;
}

.warningText {
    color: var(--Error, #C14C50);
    font-family: Montserrat;
    font-size: 14px;
}

.warningTextBold {
    font-weight: 800;
}

.checkboxSection {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 70px 0 150px 6px;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-4px);
    }

    50% {
        transform: translateX(4px);
    }

    75% {
        transform: translateX(-4px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 1s ease-in-out;
}

.buttonWrapper {
    position: absolute;
    bottom: 80px;
}

.logoMobileSection {
    display: none;
}

@media only screen and (min-width: 1200px) {
    .desktop {
        display: block;
    }

    .startStageSection {
        background-image: url("/public/background-map-blue.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .startStageWrapper {
        width: 50%;
    }

    .logoSection {
        display: none;
    }

    .logoMobileSection {
        display: block;
        width: 70%;
        color: var(--Yellow, #FFD000);
        font-family: Montserrat;
        padding-top: 5rem;
    }

    .logo {
        width: 150px;
    }

    .riddleTitle {
        font-size: 30px;
        font-weight: 400;
    }

    .textWrapper {
        margin-top: 40px;
    }

    .text {
        font-size: 20px;
    }

    .inputWrapper {
        margin-top: 40px;
        margin-bottom: 7.5rem;
    }

    .warning {
        margin-bottom: 1rem;
    }

    .warningText {
        font-size: 20px;
    }



    .teamNameInput {
        width: 100%;
        height: 50px;
        font-size: 18px;
    }

    .checkboxSection {
        width: 100%;
        margin: 23px 0 170px 0;
    }
}