.mobile {
    display: none !important;
}

.desktop {
    display: block !important;
}

.content {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 6rem;
}

/* Left side of Everyone can join section */
.leftSide {
    width: 60%;
}

.title {
    color: var(--Yellow, #FFD000);
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.leftSide .text {
    color: white;
    font-size: 24px;
    font-family: "Montserrat", Helvetica;
    margin-top: 0px;
    margin-bottom: 3rem;
}

.linkSpan {
    color: var(--yellow, #FFD000);
    font-family: Montserrat;
    font-size: 24px;
    font-style: italic;
    text-decoration-line: underline;
    cursor: pointer
}

/* Right side of Everyone can join section */
.rightSide {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 280px;
    margin-top: 4rem;
}

.elementContainer {
    display: flex;
    flex-direction: column;
}

.element {
    text-align: center;
    margin: 0 0 0 32px;
}

.row {
    display: flex;
    justify-content: space-between;
}

.link {
    all: unset;
}

.imageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    background-color: var(--yellow);
    border-radius: 10px;
}

.rightSide .text {
    color: var(--White, #FFF);
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 500;
    margin: 16px 0 23px 0;
}

@media only screen and (max-width: 600px) {


    .desktop {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }

    .title {
        font-size: 22.56px !important;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: unset;
    }

    .leftSide {
        width: 100%;
    }

    .leftSide .text, .leftSide .text .linkSpan {
        font-size: 17px !important;
    }

    .elementImg {
        scale: 0.46
    }

    .imageDiv {
        width: 52px;
        height: 52px;
        border-radius: 4.5px;
    }

    .leftSide .row .text {
        font-size: 11.2px !important;
        margin-top: 10px;
        margin-bottom: 24px;
    }

    .iconDiv {
        width: 100%;
        margin-bottom: 1rem;
    }

    .iconGroup {
        width: 100%;
    }

    .element {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0px;
    }


}

@media only screen and (max-width: 900px) and (min-width: 600px) {




    .title {
        font-size: 22.56px !important;
    }

    .content {
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        width: 100%;
        margin-top: unset;
    }

    .elementContainer {
        scale: 0.7
    }

    .rightSide {
        margin-top: unset;
    }


    /* .leftSide {
        width: 100%;
    } */

    .leftSide .text, .leftSide .text .linkSpan {
        font-size: 17px !important;
    }



    /* .imageDiv {
        width: 88px;
        height: 86px;
        border-radius: 4.5px;
    } */

    /* .leftSide .row .text {
        font-size: 11.2px !important;
        margin-top: 10px;
        margin-bottom: 24px;
    } */

    /* .element {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0px;
    } */

    .row {
        /* justify-content: space-around; */
    }

}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
    .content {
        width: 90%;
    }

    .text {
        width: 90%;
    }


}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .content {
        width: 90%;
    }

}