/* .app {
    background-image: url('./../public/naroden.webp');
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    overflow: auto;
} */

.background {
    z-index: -1000;
    position: fixed;
    top: 0;
    background-image: url('./../public/naroden.webp');
    background-size: cover;
    background-position: center center;
    overflow: auto;
    height: 100vh;
    /* This two lines work fine except for iPhone */
    /* background-attachment: fixed; */
    /* min-width: 100%; */
    width: 100vw;
}