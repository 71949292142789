.mobile {
    display: none;
}

.desktop {
    display: block;
}

.title {
    color: var(--Blue, #002950);
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 7px;
}

.redirectionWrapper {
    width: 40%;
    margin-top: 90px;
}

.redirectionText {
    color: var(--Blue, #002950);
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

/* ////////////////////////////////////////////// Highlighter /////////////////////////////////////*/
.highlighterSpan {
    position: relative;
    display: inline-block;
    z-index: 0;
}

.highlighter {
    z-index: -1;
    position: absolute;
    bottom: 3px;
    left: -5px;
    height: 23px;
    background-color: var(--yellow);
    border-radius: 10px;
    animation: loadFromLeftToRight 0.6s forwards;
}


.hide {
    display: none;
}

@keyframes loadFromLeftToRight {
    0% {
        width: 0;
    }

    100% {
        width: calc(100% + 10px);
    }
}



@media only screen and (max-width: 600px) {

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .title {
        font-size: 22.56px;
    }

    .titleWrapper .title {
        margin-bottom: 2rem;
    }

    .redirectionWrapper {
        width: calc(100% - 60px);
        margin-right: 30px;
        margin-top: 41px;
    }

    .redirectionText {
        font-size: 17px;
    }

    .highlighter {
        height: 16px;
        bottom: -1px;
        border-radius: 5px;
    }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .title {
        font-size: 22.56px;
    }

    .titleWrapper .title {
        margin-bottom: 2rem;
    }

    .redirectionWrapper {
        width: 100%;
        margin-top: 41px;
    }

    .redirectionText {
        font-size: 17px;
    }

    .highlighter {
        height: 15px;
        bottom: 2px;
        border-radius: 5px;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
    .redirectionWrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .redirectionWrapper {
        width: 60%;
    }
}