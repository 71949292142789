.desktop {
    display: block;
}

.mobile {
    display: none;
}

.itemWrapper {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: calc(70% - 146px);
    /* width: 60%; */
    border-radius: 20px;
    border: 5px solid transparent;
    padding: 0 73px 66px 73px;
    margin-bottom: 5rem;

}


.itemTitle {
    color: var(--Yellow, #FFD000);
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-family: Montserrat;
    margin: 54px 0 32px 0;
}


.riddleDescription {
    color: var(--White, #FFF);
    font-size: 24px;
    font-family: Montserrat;
    margin: 0px 0 28px 0;
    width: 100%;
}

.price {
    color: var(--Yellow, #FFD000);
    font-size: 32px;
    letter-spacing: 0.5px;
    font-family: Montserrat;
    margin: 0 0 12px 0;
}

.crossPrice {
    position: relative;
    color: #999;
    font-size: 20px;
}

.crossPrice::before {
    display: block;
    position: absolute;
    white-space: nowrap;
    top: 50%;
    left: -5%;
    height: .1em;
    width: 110%;
    opacity: .6;
    content: '';
    border-radius: .1em;
    background: red;
    transform: rotate(-15deg);
}

.riddleInfoWrapper {
    display: flex;
    flex-direction: row;
    height: 300px;
    width: 100%;
    margin-bottom: 29px;

}

.leftSection {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    min-width: 500px;
    z-index: 2;
    border: 5px solid #FFD000;
    background: var(--Yellow, #FFD000);
    border-radius: 15px;
}

.riddleImage {
    width: 100%;
    height: 290px;
    object-fit: cover;
    object-position: center;

}

.rightSection {
    overflow: hidden;
    position: relative;
    width: 50%;
    height: 290px;
    margin-left: -25px;
    border: 5px solid var(--Yellow, #FFD000);
    border-radius: 15px;
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 43px;
}

.rowKey, .value {
    color: var(--Blue, #002950);
    font-size: 20px;
    font-family: Montserrat;
    margin: 0rem;
}

.itemWrapper:hover {
    border: 5px solid var(--Yellow, #FFD000);
    border-radius: 20px;
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .itemWrapper {
        width: calc(90% - 146px);
    }
}

@media only screen and (max-width: 1200px) {
    .riddleInfoWrapper {
        flex-direction: column;
        height: unset;
    }

    .leftSection {
        width: 100%;
        height: 230px;
    }

    .rowWrapper {
        width: 90%;
    }

    .row {
        width: 100%;
    }

    .rightSection {
        width: 100%;
        margin-left: unset;
        height: 300px;
        z-index: 5;
        margin-top: -20px;
    }

    .riddleImage {
        height: 300px;

    }

    .itemWrapper {
        width: calc(90% - 146px);
        padding-bottom: 46px;
    }

    .priceSection {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 48px;
    }
}

@media only screen and (max-width: 900px) {
    .itemWrapper {
        width: calc(90% - 10px);
        padding: unset;
    }

    .itemWrapper:hover {
        border: unset;
    }

    .leftSection {
        width: calc(100% - 10px);
        background: transparent;
        min-width: unset;
        padding-top: 10px;
        padding-bottom: 15px
    }

    .rightSection {
        width: calc(100% - 10px);
        margin-top: -25px;
    }

    .itemTitle {
        font-size: 22.56px;
        margin-top: 0px;
        margin-bottom: 28px;
    }

    .rowKey {
        color: var(--yellow);
        font-size: 13.12px;
        font-style: italic;
        font-weight: 400;
    }

    .value {
        color: var(--yellow, #FFD000);
        font-size: 17px;
        font-weight: 700;
    }

    .rowWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 80%;
    }

    .row {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .riddleDescription {
        font-size: 17px;
        margin-bottom: 0;
    }

    .priceSection {
        margin-top: 36px;
    }

    .price {
        font-size: 22.56px;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}

@media only screen and (max-width: 480px) {
    .rowWrapper {
        grid-template-columns: 1fr 1.5fr;
        width: 90%;
    }
}

@media only screen and (max-width: 360px) {
    .rowWrapper {
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    .leftSection {
        height: 400px;
    }
}