.mapSection {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    border-radius: 25px;
    position: relative;
    /* box-shadow: 0px -5px 15px black; */
    /* Adjust the vertical offset here */
}

.map {
    position: relative;
    top: -70px;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.exitWrapper {
    z-index: 2;
    position: absolute;
    top: 0px;
    right: 0px;
}

.exit {
    width: 45px;
    height: 45px;
    border-radius: 100px;
    box-shadow: 0px 5px 15px black;
}

.modal {
    z-index: 2;
    position: fixed;
    align-items: center;
    width: 90%;
    bottom: -31vh;
    border-radius: 25px;
    background-color: white;
    transition: transform 0.3s ease-in-out;
    transform: translateY(70%);
}

.modal.show {
    transform: translateY(0);
}

.backdrop {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
}