.notFoundWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    top: 0;
    user-select: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0)80%, rgba(0, 41, 80, 1)), url('./../../../public/background-map-blue.webp');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.text {
    text-align: center;
    font-family: Montserrat;
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    margin-top: 100px;
    width: 400px;
}


.zoomDiv {
    position: absolute;
    right: 10%;
    top: 600px;
}

.bonus {
    position: absolute;
    cursor: default;
    user-select: none;
    text-align: center;
    left: 18%;
    top: 25%;
    font-family: Montserrat;
    font-size: 13.462px;
    font-weight: 500;
    color: var(--blue);
}

.title {
    color: var(--Yellow, #FFD000);
    font-family: Montserrat;
    font-size: 67.038px;
    font-weight: 400;
    margin-top: 8rem;
    line-height: 90px;

}

.code {
    font-size: 129.75px;
    font-weight: 600;
}

.buttonWrapper {
    z-index: 2;
    margin-top: 5rem;
}

.show {
    color: var(--yellow, #FFD000) !important;
}

@media only screen and (max-width: 1500px) {
    .zoomDiv {
        top: 700px
    }
}

@media only screen and (max-width: 1200px) {
    .text {
        margin-top: 60px;
    }

    .lineWrapper {
        top: 350px
    }

    .imgLine {
        width: 150%;
    }
}

@media only screen and (max-width: 900px) {
    .imgLine {
        width: 190%;
    }

    .lineWrapper {
        top: 250px
    }
}

@media only screen and (max-width: 600px) {

    .notFoundWrapper {
        height: 800px;
    }

    .imgLine {
        width: 100%;
    }


    .zoomDiv {
        scale: 1;
        top: 600px;
    }

    .text {
        font-size: 17px;
        width: 90%;
    }
}

@media only screen and (max-width: 360px) {
    .zoomDiv {
        scale: 0.8
    }
}