.errorContainer {
    width: 100%;
    height: 100vh;
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--yellow);
}

.text {
    margin: 0 20px 20px 20px;
    text-align: center;
    font-size: 1.25rem
}

.img {
    margin: -50px 0px 15px 0px;
}