.customCheckbox input[type="checkbox"] {
    display: none;

}

.customCheckbox .checkmark {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    outline: 1px solid var(--yellow);
    margin-right: 5px;
}

.customCheckbox input[type="checkbox"]:checked+.test .checkmark {
    border: 2px solid var(--blue);
    background-color: var(--yellow);
    border-radius: 4px;

}

.customCheckbox {
    display: flex;
    flex-direction: row;
    color: var(--Gray-dark, #707070);
    font-size: 16px;
    font-family: Montserrat;
}

.checkboxText {
    margin: 0 0 0 5px;
}

.yellow {
    text-decoration: none;
    color: var(--yellow);
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-4px);
    }

    50% {
        transform: translateX(4px);
    }

    75% {
        transform: translateX(-4px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 1s ease-in-out;
}

@media only screen and (max-width: 480px) {
    .customCheckbox {
        font-size: 13.12px;
    }

    .customCheckbox .checkmark {
        width: 10px;
        height: 10px;
        border-radius: 2px;
    }

}