.quickFactsSection {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin: 142px 0 9.125rem 0;
}

.shortInfo {
    width: 460px;
    color: var(--blue, #002950);
    font-family: Montserrat;
    font-size: 24px;
    margin-right: 14%;
    margin-top: 10px;
    margin-bottom: 10rem;
}

.quickFactsContentWrapper {
    display: flex;
    flex-direction: column;
    border: 9px solid var(--yellow);
    border-radius: 30px;
    margin-right: 15%;
    padding: 25px 0 25px 0;
}

.row {
    display: flex;
    margin: 1rem 56px 1rem 55px;
}

.iconDiv {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 34px;
}

.textWrapper {
    margin-left: -5px;
}

.value {
    color: var(--blue, #002950);
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 600;
    margin: 0;
}

.indicator {
    color: var(--Blue, #002950);
    font-family: Montserrat;
    font-size: 24px;
    margin: 0;
}

/* Visual elements about quick facts section */
.visualElementsWrapper {
    display: flex;
    position: relative;
    width: 100%
}

.lineWrapper {
    width: 100%;
    z-index: 1
}

.backgroundMask {
    position: relative;
    height: 550px;
    width: 100%;
    background-color: var(--blue);
    clip-path: url(#even-mask);
}

.buildingImg {
    position: absolute;
    top: -940px;
    z-index: 10;
}

@media only screen and (max-width: 600px) {


    .quickFactsSection {
        align-items: center;
        width: calc(100%-60px);
        padding: 0 30px !important;
        margin-top: 0;
        margin-bottom: 0;
    }

    .shortInfo {
        width: 100%;
        margin: 0;
    }

    .quickFactsContentWrapper {
        row-gap: 18px;
        border-radius: 15px;
        border: 5.373px solid var(--Yellow, #FFD000);
        margin-top: 30px;
        margin-right: 0;
        padding: 24px;
    }

    .row {
        margin: 0
    }

    .icon {
        width: 45px;
        height: 45px;
    }

    .value {
        font-size: 30.01px;
    }

    .indicator {
        font-size: 13.12px;
    }

    .buildingImg {
        position: relative;
        top: -50px;
        height: 488px;
    }

}

@media only screen and (max-width: 900px) and (min-width: 600px) {

    .quickFactsSection {
        align-items: center;
        width: calc(100%-60px);
        padding: 0 30px !important;
        margin-top: 0;
        margin-bottom: 0;
    }

    .shortInfo {
        width: 100%;
        margin: 0;
        z-index: 3;
    }

    .quickFactsContentWrapper {
        row-gap: 18px;
        border-radius: 15px;
        border: 5.373px solid var(--Yellow, #FFD000);
        margin-top: 30px;
        margin-right: 0;
        padding: 24px;
    }

    .row {
        margin: 0
    }

    .icon {
        width: 45px;
        height: 45px;
    }

    .value {
        font-size: 36.01px;
    }

    .indicator {
        font-size: 16.12px;
    }

    .buildingImg {
        position: relative;
        top: -50px;
        height: 700px;
    }
}


@media only screen and (max-width: 1200px) and (min-width: 900px) {
    .content {
        width: 90%;
    }

    .quickFactsSection {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .text {
        width: 90%;
    }

    .value {
        font-size: 36.392px;
    }

    .indicator {
        font-size: 18.196px;
    }

    .icon {
        width: 72px;
        height: 72px;
    }

    .shortInfo {
        z-index: 2;
        width: 349px;
        margin-bottom: 1rem;
        margin-right: 5%;
    }

    .quickFactsContentWrapper {
        border: unset;
        margin-right: 5%;
    }

    .buildingImg {
        top: -600px;
        height: 780px;
    }

    .backgroundMask {
        height: 230px;
    }

    .lineWrapper {
        top: -500px !important;
    }

}


@media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .content {
        width: 90%;
    }

    .shortInfo {
        margin-right: 4%;
        z-index: 50;
    }

    .quickFactsContentWrapper {
        margin-right: 5%;
    }

    .buildingImg {
        height: 1243px;
    }

    .backgroundMask {
        height: 320px;
    }
}